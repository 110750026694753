/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Auth from './mixins/AuthMixin';

require('./bootstrap');

window.Vue = require('vue');
Vue.prototype.$lang = (val, choice = null) => lang(val, choice);
Vue.prototype.$routes = (value, param = null) => window.routes(value, param);
Vue.mixin(Auth);


// Vue directives
Vue.directive('select2', {
    inserted(el) {
        $(el).on('select2:select', () => {
            const event = new Event('change', { bubbles: true, cancelable: true });
            el.dispatchEvent(event);
        });

        $(el).on('select2:unselect', () => {
            const event = new Event('change', { bubbles: true, cancelable: true })
            el.dispatchEvent(event)
        })
    },
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('cancel-remittance-modal', require('./components/CancelRemittance.vue').default);
Vue.component('cancel-delivery-modal', require('./components/CancelDelivery.vue').default);
Vue.component('complete-remittance-modal', require('./components/CompleteRemittance.vue').default);
Vue.component('delete-modal', require('./components/DeleteModal.vue').default);
Vue.component('toast', require('./components/Toast.vue').default);
Vue.component('initialize-register', require('./components/InitializeRegister.vue').default);
Vue.component('send-remittance', require('./components/SendRemittance.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

window.EventBus = new Vue;

const app = new Vue({
    el: '#app',
    data: () => {
        return {
            cancel_delivery_modal: false,
            cancel_remittance_modal: false,
            complete_remittance_modal: false,
            delete_modal: false,
            initialize_modal: false,
            record: {},
            remittance_modal: false,
        }
    },
    mounted() {
        let _this = this;

        // Handle server-side vuejs delete button
        $('.card-body tbody').on('click', '.delete-button', function (e) {
            // Get button delete info
            let record = JSON.parse($(this)[0].getAttribute('data-delete-info'));

            // Open modal and set info
            _this.record = record;
            _this.delete_modal = true;
        })

        // Handle server-side vuejs cancel delivery on demand button
        $('.card-body tbody').on('click', '#cancel-delivery', function (e) {
            // Get button cancel info
            let record = JSON.parse($(this)[0].getAttribute('data-cancel-info'));

            // Open modal and set info
            _this.record = record;
            _this.cancel_delivery_modal = true;
        })

        // Handle server-side vuejs complete remittance button
        $('.card-body tbody').on('click', '#complete-remittance', function (e) {
            // Get button cancel info
            let record = JSON.parse($(this)[0].getAttribute('data-complete-info'));

            // Open modal and set info
            _this.record = record;
            _this.complete_remittance_modal = true;
        })

        // Handle server-side vuejs cancel remittance button
        $('.card-body tbody').on('click', '#cancel-remittance', function (e) {
            // Get button cancel info
            let record = JSON.parse($(this)[0].getAttribute('data-cancel-info'));

            // Open modal and set info
            _this.record = record;
            _this.cancel_remittance_modal = true;
        })
    }
});