// window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

// Localization function
window.lang = (value, choice = null) => {
    // Split string if it has dots
    let keys = value.split('.');
    let i = 0;
    var trans = window.language;

    while (i < keys.length) {
        if (choice != null) {
            // Get correct string if choice is provided
            if (typeof trans[keys[i]] === 'string') {
                if (typeof choice === 'object') {
                    let string = trans[keys[i]];
                    Object.keys(choice).forEach(key => {
                        // Proceed if actual value is string
                        if (trans[keys[i]].includes(':' + key)) {
                            // Set value
                            string = string.replace(':' + key, choice[key])
                        }
                    });
                    // Set value
                    trans = string;
                } else {
                    // Proceed if actual value is string
                    if (trans[keys[i]].includes('|')) {
                        // Split string
                        let multi = trans[keys[i]].split('|');

                        // Select correct value
                        trans = multi.filter(x => x.includes(`{${choice}}`));

                        // Set value
                        trans = trans[0].replace(`{${choice}}`, '')
                    }
                }
            } else {
                // Set next value if it's an object
                trans = trans[keys[i]];
            }
        } else {
            // Get string if no choice is provided
            trans = trans[keys[i]];
        }

        // Get value if no translation is found
        if (!trans) {
            return value;
        };

        // Go to next string or object
        i++;
    }

    // Return translation
    return trans;
}

// Routes function
window.routes = (value, params = null) => {
    let url = window.route[value];

    if (params === null) {
        if (url.indexOf('{')) {
            url = url.substring(0, url.indexOf('{') - 1) + url.substr(url.indexOf('}') + 1, url.length);
        }
        return window.location.origin + '/' + url
    }

    if (typeof params !== 'object') {
        return window.location.origin + '/' + url.substring(0, url.indexOf('{')) + params + url.substr(url.indexOf('}') + 1, url.length);
    } else {
        Object.keys(params).forEach(param => {
            url = url.replace(`{${param}}`, params[param]);
        });
    }

    return window.location.origin + '/' + url;
}