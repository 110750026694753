const numbers = () => {
    let inputs = document.querySelectorAll('input[data-input-type="number"]');
    let max = document.querySelectorAll('.number-max');

    Array.from(inputs).forEach(input => {
        input.addEventListener('input', function (e) {
            let allowed = [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                'Tab',
                'ArrowLeft',
                'ArrowRight',
                'Backspace',
                'F5',
                'Enter',
                'Del',
                '.',
            ];
            if (!allowed.includes(e.data)) {
                this.value = this.value.replace(e.data, '');
            }
        });
    });
    Array.from(inputs).forEach(input => {
        input.addEventListener('keypress', function (e) {
            switch (e.key) {
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                case 'ArrowLeft':
                case 'ArrowRight':
                case 'Tab':
                case 'Backspace':
                case 'F5':
                case 'Enter':
                case 'Del':
                    return e.key;
                case '0':
                    if (this.classList.contains('without-zero')) {
                        if (this.value.trim() == ''
                            || this.value.length <= 0) {
                            e.preventDefault();
                            return false;
                        }
                    }
                    break;
                case '.':
                    if (this.classList.contains('only-numbers')) {
                        e.preventDefault();
                        return false;
                    }
                    if (this.value.trim() == ''
                        || this.value.length <= 0
                        || this.value.indexOf('.') >= 0) {
                        e.preventDefault();
                        return false;
                    }
                    break;
                default:
                    e.preventDefault();
                    return false;
            }
        });
    });
    Array.from(inputs).forEach(input => {
        // input.addEventListener('blur', function () {
        //     if (!this.classList.contains('with-zero')) {
        //         this.value = (this.value != '' || this.value != 0) ? this.value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : '';
        //     }
        // });

        input.addEventListener('input', function (e) {
            if (this.classList.contains('without-zero') && this.value.length == 2 && this.value.charAt(0) == '0') {
                this.value = this.value.substring(1);
            }
        });

        // input.addEventListener('mousedown', function (e) {
        //     e.preventDefault();

        //     if (this.value == '0') {
        //         this.value = '';
        //     }

        //     let val = this.value;
        //     this.value = '';
        //     this.focus();
        //     this.value = val;
        // });

        // input.addEventListener('click', function (e) {
        //     this.value = this.value.replace(/[,]/g, '');
        // });
    });

    // Max numbers quantity allowed
    Array.from(max).forEach(value => {
        value.addEventListener('keypress', function (e) {
            if (this.value.length >= this.getAttribute('data-input-number-max') && e.key != 'Enter') {
                e.preventDefault();
                return false;
            }
        });
    });
}

export default numbers;
// Initialize numbers
document.addEventListener('DOMContentLoaded', () => {
    numbers();

});