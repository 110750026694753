<template>
  <div
    class="modal fade"
    id="completeRemittanceModal"
    tabindex="-1"
    aria-labelledby="completeRemittanceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $parent.record.title }}
          </h5>
          <button
            type="button"
            class="close"
            @click.prevent="hide"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Body -->
          <p>{{ $parent.record.body }}</p>
        </div>
        <div class="modal-footer">
          <!-- cancel -->
          <button type="button" class="btn btn-default" @click.prevent="hide">
            <i class="fas fa-undo"></i>
            {{ $lang("globals.cancel") }}
          </button>

          <!-- Proceed -->
          <button
            type="button"
            class="btn btn-danger"
            @click.prevent="submitComplete"
          >
            <i class="fas fa-ban"></i>
            {{ $lang("globals.proceed") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitComplete() {
      axios
        .post(this.$parent.record.route, {
          _method: "PUT",
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          EventBus.$emit("show-toast", {
            data: this.$lang("remittance.errors.update"),
          });
          this.errors = error.response.data.errors;
        });
    },
    hide() {
      this.$parent.complete_remittance_modal = false;
      $("#completeRemittanceModal").modal("hide");
    },
  },
  mounted() {
    $("#completeRemittanceModal").modal("show");
  },
};
</script>
