<template>
  <div
    class="modal fade"
    id="remittanceModal"
    tabindex="-1"
    aria-labelledby="remittanceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Header -->
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $lang("remittance.send") }}
          </h5>
          <button
            type="button"
            class="close"
            @click.prevent="hide"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="sendRemittance">
            <!-- Bank account -->
            <p class="mb-1">
              {{ $lang("remittance.select-account") }}
            </p>
            <div
              class="form-group"
              :class="{ 'is-invalid': errors.bank_account_id }"
            >
              <select
                v-model.lazy="bank_account_id"
                class="form-control select2 d-none"
                :class="{ 'is-invalid': errors.bank_account_id }"
                v-select2
              >
                <!-- Default value -->
                <option value="" disabled>{{
                  $lang("remittance.select-account")
                }}</option>

                <!-- Bank accounts -->
                <option
                  v-for="account in accounts"
                  :key="account.id"
                  :value="account.id"
                >
                  {{ account.number }}
                </option>
              </select>
              <span
                class="invalid-feedback text-right"
                role="alert"
                v-if="errors.bank_account_id"
              >
                <strong>{{ errors.bank_account_id[0] }}</strong>
              </span>
            </div>

            <!-- Amount -->
            <p class="mb-1">{{ $lang("remittance.send-amount") }}</p>
            <div class="form-group">
              <input
                type="text"
                data-input-type="number"
                v-model="amount"
                ref="amount"
                class="form-control"
                :class="{ 'is-invalid': errors.amount }"
              />
              <span
                class="invalid-feedback text-right"
                role="alert"
                v-if="errors.amount"
              >
                <strong>{{ errors.amount[0] }}</strong>
              </span>
            </div>

            <!-- note -->
            <p class="mb-1">
              {{ $lang("remittance.note") }} ({{ $lang("globals.optional") }})
            </p>
            <div class="form-group">
              <textarea
                v-model="note"
                ref="note"
                class="form-control"
                :class="{ 'is-invalid': errors.note }"
                :placeholder="$lang('remittance.note')"
                rows="1"
              ></textarea>
              <span
                class="invalid-feedback text-right"
                role="alert"
                v-if="errors.note"
              >
                <strong>{{ errors.note[0] }}</strong>
              </span>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <!-- cancel -->
          <button type="button" class="btn btn-default" @click.prevent="hide">
            <i class="fas fa-undo"></i>
            {{ $lang("globals.cancel") }}
          </button>

          <!-- Delete -->
          <button
            type="button"
            class="btn btn-danger"
            @click.prevent="sendRemittance"
          >
            <i class="fas fa-save"></i>
            {{ $lang("globals.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numbers from '../numbers';

export default {
  created() {
    axios
      .get(this.$routes("api.bank-account.index"))
      .then((response) => {
        this.accounts = response.data.data;
      })
      .catch((error) => {
        EventBus.$emit("show-toast", {
          data: this.$lang("bank-account.errors.index"),
        });
        this.errors = error.response.data.errors;
      });
  },
  data: () => {
    return {
      bank_account_id: "",
      amount: "",
      accounts: [],
      errors: {},
      note: "",
    };
  },
  methods: {
    sendRemittance() {
      axios
        .post(this.$routes("meta.remittance.store", { metum: this.meta }), {
          amount: this.amount,
          bank_account_id: this.bank_account_id,
          cash_register_id: this.register,
          note: this.note,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          EventBus.$emit("show-toast", {
            data: this.$lang("remittance.errors.store"),
          });
          this.errors = error.response.data.errors;
        });
    },
    hide() {
      this.$parent.remittance_modal = false;
      $("#remittanceModal").modal("hide");
    },
  },
  mounted() {
    $("#remittanceModal").modal("show");

    $("#remittanceModal").on("shown.bs.modal", (e) => {
      setTimeout(() => {
        this.$refs.amount.focus();

        // Initialize select2
        $(".select2").select2();

        // Initialize numbers handler
        numbers();
      }, 0);
    });
  },
  props: {
    meta: {
      type: String,
      required: true,
    },
    register: {
      type: String,
      required: true,
    },
  },
};
</script>
