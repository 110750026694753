<template>
  <div
    class="modal fade"
    id="cancelRemittanceModal"
    tabindex="-1"
    aria-labelledby="cancelRemittanceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $parent.record.title }}</h5>
          <button
            type="button"
            class="close"
            @click.prevent="hide"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Body -->
          <p>{{ $parent.record.body }}</p>

          <!-- Cancel reason -->
          <p class="mb-1">
            {{ $lang("remittance.cancellation.cancel-reason") }}
          </p>
          <div
            class="form-group"
            :class="{ 'is-invalid': errors.cancel_reason_id }"
          >
            <select
              v-model.lazy="cancel_reason_id"
              class="form-control select2 d-none"
              :class="{ 'is-invalid': errors.cancel_reason_id }"
              v-select2
            >
              <!-- Default value -->
              <option value="" disabled>{{
                $lang("remittance.cancellation.select-cancel-reason")
              }}</option>

              <!-- Cancel reasons -->
              <option
                v-for="reason in reasons"
                :key="reason.id"
                :value="reason.id"
              >
                {{ $lang(`remittance.cancellation.${reason.reason}`) }}
              </option>
            </select>
            <span
              class="invalid-feedback text-right"
              role="alert"
              v-if="errors.cancel_reason_id"
            >
              <strong>{{ errors.cancel_reason_id[0] }}</strong>
            </span>
          </div>

          <!-- note -->
          <p class="mb-1">
            {{ $lang("remittance.note") }} ({{ $lang("globals.optional") }})
          </p>
          <div class="form-group">
            <textarea
              v-model="note"
              ref="note"
              class="form-control"
              :class="{ 'is-invalid': errors.note }"
              :placeholder="$lang('remittance.note')"
              rows="1"
            ></textarea>
            <span
              class="invalid-feedback text-right"
              role="alert"
              v-if="errors.note"
            >
              <strong>{{ errors.note[0] }}</strong>
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <!-- cancel -->
          <button type="button" class="btn btn-default" @click.prevent="hide">
            <i class="fas fa-undo"></i>
            {{ $lang("globals.cancel") }}
          </button>

          <!-- Proceed -->
          <button
            type="button"
            class="btn btn-danger"
            @click.prevent="submitCancel"
          >
            <i class="fas fa-ban"></i>
            {{ $lang("globals.proceed") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    // Get cancel reasons
    axios
      .get(this.$routes("api.remittance-cancel-reason"))
      .then((response) => {
        this.reasons = response.data.data;
      })
      .catch((error) => {
        EventBus.$emit("show-toast", {
          data: this.$lang("remittance.errors.cancel-index"),
        });
        this.errors = error.response.data.errors;
      });
  },
  data: () => {
    return {
      cancel_reason_id: "",
      errors: {},
      note: "",
      reasons: [],
    };
  },
  methods: {
    submitCancel() {
      axios
        .post(this.$parent.record.route, {
          _method: "PUT",
          cancel_reason_id: this.cancel_reason_id,
          note: this.note,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          if (error.response.data.message == 'This action is unauthorized.') {
            EventBus.$emit("show-toast", {
              data: this.$lang("remittance.errors.unauthorized"),
            });
            this.hide();
          } else {
            EventBus.$emit("show-toast", {
              data: this.$lang("remittance.errors.delete"),
            });
            this.errors = error.response.data.errors;
          }
        });
    },
    hide() {
      this.$parent.cancel_remittance_modal = false;
      $("#cancelRemittanceModal").modal("hide");
    },
  },
  mounted() {
    $("#cancelRemittanceModal").modal("show");

    $("#cancelRemittanceModal").on("shown.bs.modal", (e) => {
      setTimeout(() => {
        // Initialize select2
        $(".select2").select2();
      }, 0);
    });
  },
};
</script>
