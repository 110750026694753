<template>
  <div
    aria-live="polite"
    aria-atomic="true"
    style="position: absolute; top: 5rem; right: 1rem; z-index: 999;"
    class="d-none"
    ref="toast_box"
  >
    <div
      aria-live="polite"
      aria-atomic="true"
      style="position: relative; min-height: 200px; min-width: 300px;"
    >
      <div
        class="toast vue-toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style="position: absolute; top: 0; right: 0;"
        v-if="Object.keys(response).length"
      >
        <div class="toast-header">
          <i
            :class="
              response.status == 200
                ? 'fas fa-check text-success'
                : 'fas fa-times text-danger'
            "
          ></i>
          <strong class="mr-auto ml-2">{{
            response.status == 200
              ? $lang("globals.messages.success")
              : $lang("globals.messages.error")
          }}</strong>
          <small class="text-muted">{{
            $lang("globals.seconds-ago", { seconds: seconds })
          }}</small>
          <button
            type="button"
            class="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          {{ response.data }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      response: {},
      seconds: 0,
    };
  },
  methods: {
    countSeconds() {
      const intervalSeconds = setInterval(() => {
        // Increase seconds count
        this.seconds++;

        // Hide toast
        if (this.seconds == 6) {
          // Reset response
          this.response = {};

          // Reset seconds count
          this.seconds = 0;

          // Hide toast box
          this.$refs.toast_box.classList.add("d-none");
          clearInterval(intervalSeconds);
        }
      }, 1000);
    },
  },
  mounted() {
    // Handle event bus
    EventBus.$on("show-toast", (response) => {
      // Show toast box
      this.$refs.toast_box.classList.remove("d-none");

      // Set response
      this.response = response;

      // Handle seconds cound
      this.countSeconds();

      setTimeout(() => {
        // Show toast
        $(".vue-toast")
          .toast({
            autohide: false,
          })
          .toast("show");
      }, 0);
    });
  },
};
</script>
