let user = document.head.querySelector('meta[name=user]');

module.exports = {
    computed: {
        check() {
            return user.content && JSON.parse(user.content).check;
        },
        user() {
            if (this.check) {
                return JSON.parse(user.content);
            }

            return null;
        }
    }
}