<template>
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="deleteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $parent.record.title }}</h5>
          <button
            type="button"
            class="close"
            @click.prevent="hide"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ $parent.record.body }}</p>
          <p v-if="$parent.record.cannot_undone">
            {{ $lang("globals.cannot-undone") }}
          </p>
        </div>
        <div class="modal-footer">
          <!-- cancel -->
          <button type="button" class="btn btn-default" @click.prevent="hide">
            <i class="fas fa-undo"></i>
            {{ $lang("globals.cancel") }}
          </button>

          <!-- Delete -->
          <button
            type="button"
            class="btn btn-danger"
            @click.prevent="submitDelete"
          >
            <i class="fas" :class="$parent.record.delete_icon ? `fa-${$parent.record.delete_icon}` : 'fa-trash-alt'"></i>
            {{ $parent.record.delete_text ? $parent.record.delete_text : $lang("globals.delete") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitDelete() {
      axios
        .post(this.$parent.record.route, {
          _method: "DELETE",
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          EventBus.$emit("show-toast", error.response);
          this.hide();
        });
    },
    hide() {
      this.$parent.delete_modal = false;
      $("#deleteModal").modal("hide");
    },
  },
  mounted() {
    $("#deleteModal").modal("show");
  },
};
</script>
