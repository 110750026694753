<template>
  <div
    class="modal fade"
    id="initializeModal"
    tabindex="-1"
    aria-labelledby="initializeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Header -->
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $lang("register.cash-reconciliation.initialize") }}
          </h5>
          <button
            type="button"
            class="close"
            @click.prevent="hide"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="initializeCashRegister">
            <p class="mb-1">
              {{ $lang("register.cash-reconciliation.amount") }}
            </p>
            <!-- Initialization amount -->
            <div class="form-group">
              <input
                type="text"
                data-input-type="number"
                v-model="amount"
                ref="initialize_amount"
                class="form-control"
                :class="{ 'is-invalid': errors.amount }"
              />
              <span
                class="invalid-feedback text-right"
                role="alert"
                v-if="errors.amount"
              >
                <strong>{{ errors.amount[0] }}</strong>
              </span>
            </div>

            <!-- Active cashier -->
            <p class="mb-1" v-if="!user.isCashier">
              {{ $lang("register.cashier.select-cashier") }}
            </p>
            <div
              class="form-group"
              :class="{ 'is-invalid': errors.active_cashier }"
              v-if="!user.isCashier"
            >
              <select
                v-model.lazy="active_cashier"
                class="form-control select2 d-none"
                :class="{ 'is-invalid': errors.active_cashier }"
                v-select2
              >
                <!-- Default value -->
                <option value="" disabled>{{
                  $lang("register.cashier.select-cashier")
                }}</option>

                <!-- Cashiers -->
                <option
                  v-for="cashier in cashiers"
                  :key="cashier.id"
                  :value="cashier.id"
                >
                  {{ cashier.name }}
                </option>
              </select>
              <span
                class="invalid-feedback text-right"
                role="alert"
                v-if="errors.active_cashier"
              >
                <strong>{{ errors.active_cashier[0] }}</strong>
              </span>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <!-- cancel -->
          <button type="button" class="btn btn-default" @click.prevent="hide">
            <i class="fas fa-undo"></i>
            {{ $lang("globals.cancel") }}
          </button>

          <!-- Delete -->
          <button
            type="button"
            class="btn btn-danger"
            @click.prevent="initializeCashRegister"
          >
            <i class="fas fa-play"></i>
            {{ $lang("register.cash-reconciliation.initialize") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numbers from "../numbers";

export default {
  created() {
    axios
      .get(this.$routes("api.register.cashiers", { register: this.register }))
      .then((response) => {
        this.cashiers = response.data.data;
      })
      .catch((error) => {
        EventBus.$emit("show-toast", {
          data: this.$lang("register-meta.errors.store"),
        });
        this.errors = error.response.data.errors;
      });
  },
  data: () => {
    return {
      active_cashier: "",
      amount: "",
      cashiers: [],
      errors: {},
    };
  },
  methods: {
    initializeCashRegister() {
      axios
        .post(
          this.$routes("register.meta.store", { register: this.register }),
          { amount: this.amount, active_cashier: this.active_cashier }
        )
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          EventBus.$emit("show-toast", {
            data: this.$lang("register-meta.errors.store"),
          });
          this.errors = error.response.data.errors;
        });
    },
    hide() {
      $("#initializeModal").modal("hide");
      this.$parent.initialize_modal = false;
    },
  },
  mounted() {
    $("#initializeModal").modal("show");

    $("#initializeModal").on("shown.bs.modal", (e) => {
      setTimeout(() => {
        this.$refs.initialize_amount.focus();

        // Initialize select2
        $(".select2").select2();

        // Initialize numbers handler
        numbers();
      }, 0);
    });
  },
  props: {
    register: {
      type: String,
      required: true,
    },
  },
};
</script>
